<template>
    <modal ref="crearProducto" v-loading="loading_general" :titulo="titulo_modal" tamano="modal-lg" :cargando="cargando" @guardar="agregar_producto">
        <div class="px-4">
            <ValidationObserver ref="form1">
                <div class="row mx-3 pl-3">
                    <slim-cropper ref="cropImagen" :options="slimOptions" class="border br-10 my-auto" style="height:160px;width:160px;background:#F8F9FF;">
                        <div slot="label" class="">
                            <!-- <img src="/img/modales/camera.svg" alt="" /> -->
                            <i class="icon-star" />
                        </div>
                    </slim-cropper>
                    <div class="col pr-0">
                        <div class="row mx-0 my-1">
                            <div class="col">
                                <ValidationProvider v-slot="{errors}" :rules="{required:true,max:60}" name="nombre">
                                    <!-- <ValidationProvider v-slot="{errors}" :rules="{required:true,max:60,duplicado:unicoNombre}" name="nombre"> -->
                                    <label class="ml-2 text-general">Nombre</label>
                                    <el-autocomplete
                                    v-model="model.nombre"
                                    class="w-100"
                                    value-key="nombre_completo"
                                    :fetch-suggestions="querySearch"
                                    placeholder="Nombre del producto"
                                    :trigger-on-focus="false"
                                    @select="setSugerencia"
                                    />
                                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row mx-0 my-1">
                            <div class="col-6">
                                <ValidationProvider v-slot="{errors}" :rules="{max:20}" name="sku">
                                    <label class="ml-2 text-general">SKU</label>
                                    <el-input v-model="model.sku" size="small" maxlength="20" show-word-limit />
                                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                </ValidationProvider>
                            </div>
                            <div class="col-6">
                                <ValidationProvider v-slot="{errors}" :rules="{max:30}" name="sku">
                                    <label class="ml-2 text-general">BarCode</label>
                                    <el-input v-model="model.barcode" size="small" maxlength="30" show-word-limit />
                                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="row mx-0 my-1">
                            <div class="col-12">
                                <ValidationProvider v-slot="{errors}" :rules="{max:25}" name="presentación">
                                    <label class="text-general">Presentación</label>
                                    <el-input v-model="model.presentacion" size="small" maxlength="25" show-word-limit />
                                    <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 my-3">
                    <div class="col text-center text-general">
                        Este producto es un kit
                        <el-switch v-model="model.kit" class="switch-red" :active-value="1" :inactive-value="0" />
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col">
                        <label class="text-general">Descripción</label>
                        <el-input v-model="model.descripcion" type="textarea" :rows="3" maxlength="500" show-word-limit onkeypress="return event.keyCode != 13"/>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col">
                        <ValidationProvider v-slot="{errors}" rules="required" name="unidad medida">
                            <label class="text-general">Unidad de medida de venta</label>
                            <el-select v-model="model.id_unidad" size="small" class="w-100">
                                <el-option
                                v-for="item in unidades"
                                :key="item.id"
                                :label="item.nombre"
                                :value="item.id"
                                />
                            </el-select>
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                    <div class="col">
                        <ValidationProvider v-slot="{errors}" rules="required" name="vender por">
                            <label class="text-general">Vender por</label>
                            <el-select v-model="model.cantidad_tipo" size="small" class="w-100" @change="cambio_tipo">
                                <el-option
                                label="Unidad"
                                :value="1"
                                />
                                <el-option
                                label="Fracción"
                                :value="2"
                                />
                            </el-select>
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-6">
                        <ValidationProvider v-slot="{errors}" :rules="{decimal:model.cantidad_tipo == 1 ? 0 : 1,max_value:99.9,min_value:0.1}" name="cantidad mínima">
                            <label class="text-general">Cantidad mínima de venta</label>
                            <el-input v-model="model.minima_compra_permitida" size="small" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                    <div class="col-6">
                        <ValidationProvider v-slot="{errors}" rules="numeric|max_value:65000" name="cantidad maxima">
                            <label class="text-general">Cantidad maxima de venta</label>
                            <el-input v-model="model.cantidad_maxima" size="small" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col-6">
                        <ValidationProvider v-slot="{errors}" :rules="{required:true, decimal:model.cantidad_tipo == 1 ? 0 : 2,max_value:9999.999,min_value:0.1}" name="cantidad aumentada click">
                            <label class="text-general">Cantidad aumentada click +</label>
                            <el-input v-model="model.cantidad_minima" size="small" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                    <div v-if="conCedisProducto" class="col-6">
                        <ValidationProvider v-slot="{errors}" rules="required" name="valor">
                            <label class="pl-3 text-general"> Precio </label>
                            <div class="d-middle">
                                <div class="bg-whitesmoke2 py-1 px-1 mr-1 border" style="border-radius:12px 0px 0px 12px">
                                    {{ sigla_actual }}
                                </div>
                                <input-miles-moneda v-model="model.valor" :id-moneda="id_moneda_cedis" maxlength="13" />
                            </div>
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="row m-3">
                    <div class="col position-relative">
                        <div class="row align-items-center mx-0">
                            <p class="col-auto px-0 f-16 pl-1 text-general">
                                Peso Volumétrico
                            </p>
                            <div class="col-auto px-2">
                                <el-popover
                                placement="right"
                                width="300"
                                trigger="click"
                                >
                                    <div class="custom-scroll overflow-auto" style="height:250px;">
                                        <div v-for="(volumen, v) in volumenes" :key="v" class="row mx-0 mb-3">
                                            <p class="col-12 f-17 f-600 text-general">
                                                {{ `${volumen.nombre} - ${volumen.volumen}` }}
                                            </p>
                                            <p class="col-12 f-13 mt-1">
                                                {{ volumen.descripcion }}
                                            </p>
                                            <div class="col-12 mt-2">
                                                <div class="row mx-0">
                                                    <img v-for="(data2,d) in volumen.imagenes" :key="d" :src="data2.imagen_mini" class="obj-cover border br-10 mr-2 mb-2" width="50" height="50" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div slot="reference" class="cr-pointer">
                                        <i class="icon-info-circled-alt f-20 text-general" />
                                    </div>
                                </el-popover>
                            </div>
                        </div>
                        <el-select v-model="model.id_volumen" size="small" class="w-100">
                            <el-option
                            v-for="item in volumenes"
                            :key="item.id"
                            :label="`${item.nombre} - ${item.volumen}`"
                            :value="item.id"
                            />
                        </el-select>
                    </div>
                    <div class="col-6">
                        <ValidationProvider v-slot="{errors}" :rules="{ required:false, decimal:2, max_value: 9999.99, greaterThanZero: false }" name="peso">
                            <label class="text-general pt-1">Peso (Kg)</label>
                            <el-input v-model="model.peso" size="small" />
                            <p class="text-danger w-100 f-11"> {{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>


                <div class="row m-3">
                    <div class="col">
                        <label class="text-general">Categorías</label>
                        <el-select v-model="value_categoria" class="w-100" size="small" placeholder="Select" filterable @change="agregar_categoria">
                            <el-option-group
                            v-for="(group,key) in categorias"
                            :key="key"
                            :label="group.nombre"
                            >
                                <el-option
                                v-for="(item,key2) in group.subcategorias"
                                :key="key2"
                                :label="item.nombre"
                                :value="item.id"
                                :disabled="item.disabled"
                                />
                            </el-option-group>
                        </el-select>
                        <div class="row mx-0">
                            <div v-for="(c,index) in categorias_aplicadas" :key="`key1${index}`" class="br-20 f-12 bg-general3 pl-2 text-white d-middle m-2">
                                {{ c.nombre }}
                                <i class="icon-cancel f-20 cr-pointer ml-2" @click="remover_categoria(c.id)" />
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <label class="text-general">Etiquetas</label>
						<el-select v-model="value_etiqueta" class="w-100" size="small" placeholder="Select" filterable @change="agregar_etiqueta">
                            <el-option-group
                            v-for="(group,key) in etiquetas"
                            :key="key"
                            :label="group.nombre"
                            >
                                <el-option
                                v-for="(item,key2) in group.etiquetas_hijas"
                                :key="key2"
                                :label="item.nombre"
                                :value="item.id"
                                :disabled="item.disabled"
                                />
                            </el-option-group>
                        </el-select>
                        <div class="row mx-0">
                            <div v-for="(e,index2) in etiquetas_aplicadas" :key="`key2${index2}`" class="br-20 f-12 bg-general3 pl-2 text-white d-middle m-2">
                                {{ e.nombre }}
                                <i class="icon-cancel f-20 cr-pointer ml-2" @click="remover_etiqueta(e.id)" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mx-0 my-3">
                    <div class="col text-center text-general">
                        Exhibir producto en el  catálogo
                        <el-switch v-model="model.estado" class="switch-red" :active-value="1" :inactive-value="0" />
                    </div>
                </div>
                <div class="row mx-0 my-3 j-center">
                    <div class="col text-center">
                        Imágenes adicionales del producto
                        <el-upload
                        action="#"
                        multiple
                        :auto-upload="false"
                        accept="image/jpeg,image/x-png"
                        list-type="picture-card"
                        :on-change="agregar"
                        :on-remove="remover"
                        :file-list="fileList"
                        >
                            <i class="el-icon-plus" />
                        </el-upload>
                    </div>
                </div>
            </ValidationObserver>
        </div>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'
import Productos from "~/services/productos/productos"
export default {
    props:{
        duplicar:{
            type: Boolean,
            default: false
        },
        conCedisProducto:{
            type: Boolean,
            default: false
        }
    },
    data(){
        return {
            id_cedi_ruta: parseInt(this.$route.params.id_cedis),
            titulo_modal: '',
            fileList:[],
            model: {
                nombre: '',
                sku:null,
                barcode:null,
                kit:0,
                descripcion: '',
                id_unidad: '',
                id_volumen: '',
                peso:0.00,
                cantidad_tipo: '',
                cantidad_minima: 1,
                minima_compra_permitida: null,
                cantidad_maxima: null,
                presentacion: '',
                estado:1
            },
            slimOptions: {
                ratio: '1:1',
                label: 'Imagen Aqui',
                minSize: { width: 100, height: 100 },
                buttonCancelLabel: 'Cancelar',
                buttonConfirmLabel: 'ok'
            },
            loading_general:false,
            cargando:false,
            volumenes: [],
            categorias: [],
            value_categoria:null,
            categorias_aplicadas:[],
            etiquetas: [],
            value_etiqueta:null,
            etiquetas_aplicadas:[],
            unidades: [],
            payload:{
                imagenes: [],
                ids_old: []
            },
            sigla_actual: '',
            id_moneda_cedis:0,
            id_producto_old: null
        }
    },
    computed:{
        ...mapGetters({
            id_cedis_navbar:'cedis/id_cedis_navbar',
            id_cedis:'cedis/id_cedis'
        }),
    },
    watch:{
        conCedisProducto(val){
            if(val){
                this.id_moneda_cedis = this.calcularMonedaCedis(this.id_cedi_ruta ? this.id_cedi_ruta : this.id_cedis_navbar)
                this.sigla_actual = this.calcularSiglaActual(this.id_cedi_ruta ? this.id_cedi_ruta : this.id_cedis_navbar)

            }
        }
    },
    methods: {
        async toggle(info={}){
            this.limpiar()
            if(this.duplicar){
                this.titulo_modal = 'Duplicar producto'
                await this.datos_basicos()
                this.cargarDatosDuplicar(info)
            }else{
                this.titulo_modal = 'Crear producto'
                this.datos_basicos()

            }
            if(this.conCedisProducto){
                this.id_moneda_cedis = this.calcularMonedaCedis(this.id_cedi_ruta ? this.id_cedi_ruta : this.id_cedis_navbar)
                this.sigla_actual = this.calcularSiglaActual(this.id_cedi_ruta ? this.id_cedi_ruta : this.id_cedis_navbar)

            }
            this.$refs.crearProducto.toggle()
        },
        cargarDatosDuplicar(info){
            this.id_producto_old = info.id_producto_old
            this.model = info.model
            this.categorias_aplicadas = info.categorias
            this.categorias_aplicadas.forEach(e => {
                e.disabled = false
            });
            const arrayCategorias = info.categorias.map(e=> e.id)

            this.etiquetas_aplicadas = info.etiquetas
            this.etiquetas_aplicadas.forEach(e => {
                e.disabled = false
            });
            const arrayEtiquetas= info.etiquetas.map(e=> e.id)

            this.categorias.forEach(e => {
                e.subcategorias.forEach(sub =>{
                    sub.disabled = arrayCategorias.includes(sub.id)
                })
            });
            this.etiquetas.forEach(e => {
                e.disabled = arrayEtiquetas.includes(e.id)
            });
            // imagenes
            if(info.foto && info.foto!=='/img/no-imagen/mercado.svg'){
                this.$refs.cropImagen.instanciaCrop.load(info.foto)
            }else{
                this.$refs.cropImagen.instanciaCrop.remove();
            }
            info.fileList.forEach(async element => {
                this.fileList.push({name:element.name, url: element.url})
            });
        },
        limpiar(){
            this.fileList=[],
            this.model =  {
                nombre: '',
                sku:null,
                barcode:null,
                descripcion: '',
                id_unidad: '',
                id_volumen: '',
                peso:0.00,
                cantidad_tipo: '',
                cantidad_minima: 1,
                minima_compra_permitida: null,
                cantidad_maxima: null,
                presentacion: '',
                estado:1
            }
            this.value_categoria= null
            this.categorias_aplicadas= []
            this.value_etiqueta= null
            this.etiquetas_aplicadas= []
            this.$refs.form1.reset()
            this.$refs.cropImagen.instanciaCrop.remove()
        },
        async datos_basicos(){
            try {
                this.loading_general = true

                const {data} = await Productos.selects_admin()
                this.categorias =  data.categorias
                this.etiquetas =  data.etiquetas
                this.unidades =  data.unidades
                this.volumenes =  data.volumenes
                // const allProductsName = data.nombres_productos
                // this.nombres_productos = allProductsName
                // this.todos_sku = allProductsName

            } catch (e){
                this.error_catch(e)
            } finally {
                this.loading_general = false
            }
        },
        async agregar_producto(){
            try {
                const valid = await this.$refs.form1.validate()

                if(valid){
                    this.cargando = true
                    if(this.categorias_aplicadas.length === 0){
                        this.notificacion('Advertencia','Por favor seleccione almenos una categoria','warning')
                        return
                    }
                    this.model.etiquetas = this.etiquetas_aplicadas
                    this.model.imagen = this.$refs.cropImagen.instanciaCrop.dataBase64.output.image;

                    this.model.categorias = JSON.stringify(this.categorias_aplicadas)
                    this.model.etiquetas = JSON.stringify(this.etiquetas_aplicadas)
                    await this.obtenerImagenesNuevasProducto()

                    this.model.file_new = this.payload.imagenes
                    this.model.file_old= this.payload.ids_old

                    if(this.conCedisProducto){
                        this.model.id_cedis = this.id_cedi_ruta? this.id_cedi_ruta:this.id_cedis_navbar
                    }
                    if(this.duplicar){
                        this.model.id_producto_old= this.id_producto_old
                    }
                    this.model.sku = this.model.sku == '' ? null : this.model.sku
                    this.model.barcode = this.model.barcode == '' ? null : this.model.barcode
                    this.model.descripcion = this.model.descripcion == '' ? null : this.model.descripcion
                    this.model.presentacion = this.model.presentacion == '' ? null : this.model.presentacion
                    this.model.cantidad_maxima = this.model.cantidad_maxima == '' ? 0 : this.model.cantidad_maxima
                    this.model.minima_compra_permitida = !this.model.minima_compra_permitida ? 1 : this.model.minima_compra_permitida
                    var newModel = this.crear_formData(this.model)
                    var data = null
                    if(this.duplicar){
                        var {data} = await Productos.duplicarProductoConTodosCedisProducto(newModel)
                        this.notificacion('Mensaje','Producto duplicado correctamente','success')
                    }else if(this.conCedisProducto){
                        var {data} = await Productos.agregarProductoConCedisProducto(newModel)
                        this.notificacion('Mensaje','Producto creado correctamente','success')
                    }else{
                        this.notificacion('Mensaje','Producto creado correctamente','success')
                        var {data} = await Productos.agregar(newModel)
                    }



                    let obj = {
                        categorias: this.categorias_aplicadas,
                        producto:data.producto
                    }
                    this.$emit('update',obj)
                    this.$refs.crearProducto.toggle()
                }

            } catch (e){
                this.error_catch(e)
            } finally {
                this.cargando = false
            }
        },
        cambio_tipo(){
            this.model.cantidad_minima = null
            this.model.minima_compra_permitida = null
        },
        agregar_categoria(id){

            let item = null;
            for (const cat of this.categorias){
                item = cat.subcategorias.find(o=>o.id === id)
                if(item) break
            }
            item.disabled = true
            this.categorias_aplicadas.push(item)
            this.value_categoria = null
        },
        remover_categoria(id){
            let item = null;
            for (const cat of this.categorias){
                item = cat.subcategorias.find(o=>o.id === id)
                if(item) break
            }
            item.disabled = false
            this.categorias_aplicadas.splice(this.categorias_aplicadas.indexOf(item), 1)

        },
        agregar_etiqueta(id){
			let item = null;
            for (const cat of this.etiquetas){
                item = cat.etiquetas_hijas.find(e => e.id === id)
                if(item) break
            }
            item.disabled = true
            this.etiquetas_aplicadas.push(item)
            this.value_etiqueta = null
        },
        remover_etiqueta(id){
			let item = null;
            for (const cat of this.etiquetas){
				item = cat.etiquetas_hijas.find(o=>o.id === id)
                if(item) break
            }
            item.disabled = false
            this.etiquetas_aplicadas.splice(this.etiquetas_aplicadas.indexOf(item), 1)

        },
        agregar(file){
            this.fileList.push({name:file.name, url: file.url, raw: file.raw})
            this.payload.imagenes.push(file.raw)
        },
        remover(file, listaArchivos){
            this.fileList = listaArchivos
            let quedarian = this.payload.imagenes.filter(e => e.name!== file.name)
            this.payload.imagenes = quedarian
        },
        async obtenerImagenesNuevasProducto(){
            if (_.isEmpty(this.fileList)) return;
            for await (let x of this.fileList){
                if(x.raw){

                }else{
                    this.payload.ids_old.push(x.name);
                }
            }
        },
        generarBase64: async archivo => {
            const lector = new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(archivo);
                reader.onload = () => resolve(reader.result);
            });

            return await lector;
        },
        delayMilisegundos(ms){
            return new Promise(resolve => setTimeout(resolve, ms));
        },

        async querySearch(string, cb){
            await this.delayMilisegundos(500)
            const {data} = await Productos.getCoincidencias({text: string})
            cb(data.productos);
        },
        setSugerencia(item){
            this.model.nombre = item.nombre
            this.model.presentacion = item.presentacion
            //console.log(item);
        }


    },
}
</script>
<style lang="css" scoped>

.border-blue{
    border: 1px solid #0892DD;
}
.border-red{
    border: 1px solid #FF612B;
}
.border-purple{
    border: 1px solid #9508EB;
}
.border-pink{
    border: 1px solid #D6017B;
}
</style>
